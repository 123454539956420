import { RefObject, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './theme.scss';
import './wdyr';
import log from 'loglevel';
import { COOKIE_APP_VERSION_KEY, LS_TOKEN_KEY } from './core/constants/Storage';

interface SIMGlobal {
  data: SIMObject;
  devUIStore: SIMObject;
  simulation: SimScript;
  version: string;
}

declare global {
  interface Window {
    newrelic: NewRelicAPI;

    SIMNET_WEBREADER_URL: string;
    ReadSpeaker?: ReadSpeakerAPI;
    readpage?: ReadSpeakerReadPage;
    rsConf: ReadSpeakerRSConf;

    SP_EMBED: boolean;
    SP_MODAL_ROOT: RefObject<HTMLDivElement>;
    SP_VERSION: string;

    // These are used for sim exam view
    CDN_CONTENT_LOCATION: string;
    SIM: SIMGlobal;
    SIM_HOST: string;
    [key: string]: string;

    setAppVersion(version: string | null): void;
  }
}

const ENABLE_MSW = false; // process.env.NODE_ENV === 'development';

window.setAppVersion = (version) => {
  const d = new Date();
  if (version) {
    d.setDate(d.getDate() + 1); // 1 day later than now
  } else {
    d.setTime(0);
  }
  const cookieStr = [
    `${COOKIE_APP_VERSION_KEY}=${version}`,
    `expires=${d.toUTCString()}`,
    'path=/',
    `domain=${document.location.hostname}`
  ].join('; ');
  log.info(cookieStr);
  document.cookie = cookieStr;
  setTimeout(() => document.location.reload(), 1000);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SIMObject = Record<string, any>;

window.SP_VERSION = process.env.REACT_APP_VERSION as string;
window.SP_EMBED = window.location.href.indexOf('/sp/embed') !== -1;

function forceHttps() {
  if (window.location.protocol !== 'https:') {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
    return true;
  }
  return false;
}

async function enableMocking() {
  if (!ENABLE_MSW) {
    return;
  }
  if ('serviceWorker' in navigator) {
    try {
      const { generateWorker } = await import('./mocks/browser');
      const worker = generateWorker();

      worker
        .start({
          onUnhandledRequest: 'bypass'
        })
        .catch(() => {});
    } catch (e) {
      /* NOOP */
    }
  }
}

async function addPackages() {
  const baseUrl = process.env.PUBLIC_URL || '';

  if (!document.getElementById('force-cache-clear')) {
    const el = document.createElement('script');
    el.setAttribute('id', 'force-cache-clear');

    el.src = baseUrl + `/force-cache-clear.cjs.js?v=${Date.now()}`;
    document.body.appendChild(el);
  }
  if (!document.getElementById('browser-support')) {
    const el = document.createElement('script');
    el.setAttribute('id', 'browser-support');
    el.src = baseUrl + `/browser-support.cjs.js?v=${Date.now()}`;
    document.body.appendChild(el);
  }

  return new Promise<void>((resolve) => {
    const i = setInterval(() => {
      if (
        localStorage.getItem('sp_browser_support') !== null ||
        window.BROWSER_SUPPORT !== undefined
      ) {
        clearInterval(i);
        resolve();
      }
    }, 500);
  });
}

(async () => {
  // Force HTTPS. Logging in won't work through http, and the prod servers are not
  // configured to enforce it, so we have to enforce it ourselves.
  if (forceHttps()) {
    return;
  }

  // Import Mock Service Worker library
  await enableMocking();

  // Load browser-support and force-cache-clear
  await addPackages();

  if (navigator.cookieEnabled) {
    const u = new URL(document.location.href);
    if (u.searchParams.has('access_token')) {
      localStorage.setItem(
        LS_TOKEN_KEY,
        u.searchParams.get('access_token') as string
      );
      u.searchParams.delete('access_token');
      document.location.href = u.toString();
      return;
    }
    /**
     * Reset count on successful load
     * Ensures we do not loop infinitely
     * @see ./packages/force-cache-clear.ts
     */
    localStorage.setItem('sp_force_reload_count', '0');

    /**
     * Do not render App if browser
     * does not meet our minimum browser partial support
     * @see ./packages/browser-support.ts
     */
    const browserSupport = localStorage.getItem('sp_browser_support');

    if (browserSupport) {
      const { partialPassed } = JSON.parse(browserSupport) as BrowserInfo;
      if (!partialPassed) {
        return;
      }
    }
  } else {
    // browser-support.js should render a view
    return;
  }

  const USE_STRICT_MODE = false;
  const Wrapper = (await import('./app/Wrapper')).Wrapper;
  const app = <Wrapper />;

  ReactDOM.render(
    USE_STRICT_MODE ? <StrictMode>{app}</StrictMode> : app,
    document.getElementById('app')
  );
})();
